import axios from '@/utils/axios.js'


export function GetBill (ckhm) {
    return axios.get('/api/User/GetBill?ckhm=' + ckhm)
}

export function GetJZXH (ckhm, pd) {
    return axios.get('/api/DrugLogistic/GetJzxh?CardID=' + ckhm + '&pd=' + pd)
}

export function GetJLLB (jzxh, pd) {
    return axios.get('/api/DrugLogistic/GetJzList?jzxh=' + jzxh + '&pd=' + pd)
}

export function GetJLXQ (jzxh, pd) {
    return axios.get('/api/DrugLogistic/GetJzDetail?jzxh=' + jzxh + '&pd=' + pd)
}

export function GetTestList (kssj, jssj, jzxh) {
    return axios.get('/api/Report/GetReportList?start=' + kssj + '&end=' + jssj + '&cardID=' + jzxh + '&name=' + '')
}

export function GetRecordMx (tmh) {

    return axios.post('/api/Report/GetRecordMx', { tmh: tmh })
}

export function GetXTXX (userId, pageSize, pageIndex) {
    return axios.get('/api/User/GetSystemMsg?userId=' + userId + '&pageSize=' + pageSize + '&pageIndex=' + pageIndex)

}

export function GetSMDH (smdh) {

    return axios.get('/api/Report/ScanCode?smdh=' + smdh)
}

export function GetLSXX (targetId) {

    return axios.get('/api/User/GetUserHisChatMsgBySendDateDecreasing?lastSendDate=' + "2000-01-01 00:00:00" + "&targetId=" + targetId + "&num=5")
}

export function GetCYTMH (smdh) {
    return axios.get('/api/Report/GetCYTMH?smdh=' + smdh)
}

export function GetZX (CardID) {
    return axios.get('/api/User/GetConsultRecordList?patientId=' + CardID)
}

export function CopyApplyInfo (ddbm, TJ) {
    return axios.post('/api/OutPatient/CopyApplyInfo?old_ddbm=' + ddbm, TJ)
}
export function GetJZLB (CardID, index) {
    return axios.get('/api/DrugLogistic/GetRecordList?CardID=' + CardID + '&index=' + index + '&size=10')
}
export function GetDDZX (ddbm) {
    return axios.get('/api/User/GetConsultRecordListByDDBM?ddbm=' + ddbm)
}
export function InforMationRead (TJ) {
    return axios.post('/api/User/SetMsgRead', TJ)
}

export function GetInforMationUnRead (ID) {
    return axios.get('/api/User/GetUnReadMsgCount?senderId=' + ID)
}
export function GetExamDetails (EXAM_RPT_FLOW) {
    return axios.get('/api/Report/GetExamDetails?EXAM_RPT_FLOW=' + EXAM_RPT_FLOW)

}
export function GetestList (start, end, patientSN) {
    return axios.get('/api/Report/GetReportList?start=' + start + '&end=' + end + '&cardID=' + patientSN + '&name=' + " ")
}
//充值
export function Pay (postData) {
    return axios.post('/api/User/Pay', postData)
}

export function GetestLists (kssj, jssj, jzxh, pd) {
    return axios.get('/api/Report/GetTestList?start=' + kssj + '&end=' + jssj + '&patientID=' + jzxh + '&pd=' + pd)
}
export function GetRecipeStatus (cfsb) {
    return axios.post('/api/User/GetRecipeStatus?cfsb=' + cfsb)
}
//获取总院检验明细
export function GetZyReportDetails (sampleno) {
    return axios.get('/api/Report/GetZyReportDetails?sampleno=' + sampleno)
}
// 取消申请
export function CancelApplication (data) {
    return axios.get('/api/DrugLogistic/CancelApplication?ddbm=' + data)
}

export function GetRecordMX_WSW (tmh) {
    return axios.post('/api/Report/GetRecordMx_WSW', { tmh: tmh })
}
export function GetZYOtherReportDetails (data) {
    return axios.get(`/api/Report/GetZyOtherReportDetails?sampleno=${data}`)
}

// 修改地址
export function ChangeAddress (data) {
    return axios.post('/api/DrugLogistic/ChangeAddress', data)
}

// 医生是否可以预约
export function IsReservable (data) {
    return axios.get(`/api/OutPatient/IsReservable?ddbm=${data}`)
}